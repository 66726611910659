<template>
  <v-container fluid class="ma-0 pa-0 white">
    <v-layout row wrap :pa-5="$vuetify.breakpoint.mdAndUp">
      <v-flex xs12 class="max-width-1400 full-width ma-auto">
        <v-layout row wrap>
          <v-flex xs12 sm7 md8 :class="{'pa-3': $vuetify.breakpoint.mdAndUp, 'pr-2 pl-3 py-3': $vuetify.breakpoint.smOnly}">
            <v-card color="light-background" flat tile class="px-2">
              <v-card-text>
                <h1
                  :class="{'font-35': $vuetify.breakpoint.mdAndUp, 'font-20': $vuetify.breakpoint.smAndDown}"
                  class="primary--text graphik-light mb-2 mt-0"
                >{{ $ml.get('issuance_title_1') }} <span class="graphik-bold">{{ $ml.get('issuance_title_2') }}</span></h1>

                <h2
                  :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                  class="graphik-light mb-4 dark-grey--text"
                >{{ $ml.get('issuance_description') }}</h2>

                <!-- Mobile -->
                <v-layout row wrap>
                  <v-expansion-panel v-if="product.product && $vuetify.breakpoint.xsOnly" class="hidden-shadow my-3" v-model="panel">
                    <v-expansion-panel-content class="light-background ">
                      <template v-slot:header>
                        <v-flex d-flex>
                          <h3 class="text-xs-left graphik-bold font-20 primary--text mb-0">{{ $ml.get('general_order_summary') }}</h3>
                          <v-spacer></v-spacer>
                          <v-icon color="primary">fas fa-chevron-down</v-icon>
                        </v-flex>
                      </template>

                      <v-card color="light-background" flat tile>
                        <v-card-text class="pa-0">
                          <v-img
                            v-if="company_logo"
                            max-width="250px"
                            contain
                            position="center center"
                            class="ma-auto"
                            :src="company_logo"
                            :lazy-src="company_logo"
                          />

                          <p class="graphik-bold font-17 dark-grey--text">{{ $ml.get('quoter_insurace') }} {{ $route.params.type }}</p>

                          <p class="graphik-medium uppercase font-17 dark-grey--text ma-0">{{ $ml.get('fields_coverage') }} {{ product.product.public_name }}</p>

                          <v-expansion-panel class="hidden-shadow" v-model="coverages_panel">
                            <v-expansion-panel-content class="light-background">
                              <template v-slot:header>
                                <p class="graphik-medium font-14 dark-grey--text ma-0">{{ $ml.get('general_view_details') }} <v-icon small>{{ coverages_panel === 0 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</v-icon></p>
                              </template>

                              <v-card flat height="100%" class="light-background">
                                <v-card-text class="pa-0">
                                  <p class="graphik-light font-14 dark-grey--text ma-0" v-for="(coverage, index) in product.insurance_information.coverage" :key="index">• {{ coverage.text }}</p>
                                </v-card-text>
                              </v-card>
                            </v-expansion-panel-content>
                          </v-expansion-panel>

                          <v-divider class="grey my-3"></v-divider>

                          <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_validity') }}</p>
                          <p class="graphik-light font-15 dark-grey--text">{{ product.quote.start_date | moment('LL') }} {{ $ml.get('general_to') }} {{ product.quote.end_date | moment('LL') }}</p>

                          <template v-if="$route.params.type === 'auto'">
                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_model') }}</p>
                            <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_model }}</p>

                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_brand') }}</p>
                            <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_brand }}</p>

                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_uuid') }}</p>
                            <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_version }}</p>
                          </template>

                          <template v-if="$route.params.type === 'hogar'">
                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_address') }}</p>
                            <p class="graphik-light font-15 dark-grey--text">{{ product.quote.street }} {{ product.quote.street_number }}, {{ product.quote.neighborhood }} {{ product.quote.zip_code }}. {{ product.quote.city }}, {{ product.quote.state }}.</p>
                          </template>

                          <v-divider class="grey my-3"></v-divider>

                          <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_coupon') }}</p>
                          <p class="graphik-light font-15 dark-grey--text">{{ product.quote.coupon.coupon_code }}</p>

                          <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_payment_method') }}</p>
                          <p class="graphik-light font-15 dark-grey--text">{{ product.quote.frequency_description }}</p>

                          <v-divider class="grey my-3"></v-divider>

                          <v-flex d-flex>
                            <p class="graphik-medium font-20 dark-grey--text ma-0">{{ $ml.get('fields_total') }}</p>
                            <v-spacer></v-spacer>
                            <p class="graphik-light font-20 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.total | price }}</p>
                          </v-flex>

                          <v-divider class="grey my-3"></v-divider>

                          <v-flex d-flex>
                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_first_payment') }}</p>
                            <v-spacer></v-spacer>
                            <p class="graphik-light font-15 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.first_payment | price }}</p>
                          </v-flex>

                          <v-flex d-flex v-if="product.insurance_information.next_payment">
                            <p class="graphik-medium font-15 dark-grey--text ma-0">{{ product.insurance_information.frequency - 1 }} {{ $ml.get('fields_next_payment') }}</p>
                            <v-spacer></v-spacer>
                            <p class="graphik-light font-15 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.next_payment | price }}</p>
                          </v-flex>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-layout>

                <!-- Data -->
                <v-layout row wrap>
                  <v-flex xs12 class="max-width-1400 full-width ma-auto">
                    <h3 
                      :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-17': $vuetify.breakpoint.smAndDown}"
                      class="graphik-bold primary--text my-4"
                    >{{ $ml.get('issuance_contact') }}</h3>

                    <v-form v-model="valid_contact">
                      <v-container fluid pa-0 ma-0 grid-list-xl>
                        <v-layout row wrap>
                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_name') }}</p>

                            <v-text-field
                              v-model="issuance.first_name"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading || disabled"
                              :rules="g_required_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_last_name') }}</p>

                            <v-text-field
                              v-model="issuance.last_name"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading || disabled"
                              :rules="g_required_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_second_last_name') }}</p>

                            <v-text-field
                              v-model="issuance.second_last_name"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading || disabled"
                              :rules="g_required_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_email') }}</p>

                            <v-text-field
                              v-model="issuance.email"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading || disabled"
                              :rules="g_email_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_cellphone') }}</p>

                            <v-text-field
                              v-model="issuance.cellphone"
                              solo
                              clearable
                              required
                              :disabled="g_isLoading || disabled"
                              mask="phone"
                              :rules="g_phone_rules"
                              class="hidden-shadow small-input"
                            ></v-text-field>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_gender') }}</p>

                            <v-autocomplete
                              v-model="issuance.gender"
                              :items="options.genders"
                              solo
                              clearable
                              :disabled="g_isLoading || disabled"
                              required
                              :rules="g_required_rules"
                              :no-data-text="$ml.get('general_no_results')"
                              class="hidden-shadow small-input"
                            ></v-autocomplete>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p class="graphik-medium font-17 mb-2 dark-grey--text">{{ $ml.get('fields_birthday') }}</p>

                            <v-menu
                              :close-on-content-click="false"
                              v-model="birthdate_menu"
                              :nudge-right="40"
                              lazy
                              transition="scale-transition"
                              offset-y
                              full-width
                              max-width="290px"
                              min-width="290px"
                            >
                              <v-text-field
                                solo
                                slot="activator"
                                v-model="birthdate_formatted"
                                persistent-hint
                                readonly
                                append-icon="event"
                                required
                                :disabled="g_isLoading || disabled"
                                :rules="g_required_rules"
                                class="hidden-shadow small-input"
                              ></v-text-field>

                              <v-date-picker
                                ref="birthdate_picker"
                                v-model="issuance.birthday"
                                no-title
                                locale="es-ES"
                                :max="adult_only"
                                min="1900-01-01"
                                @input="birthdate_menu = false"
                              ></v-date-picker>
                            </v-menu>
                          </v-flex>

                          <v-flex xs12 sm6>
                            <p
                              :class="{'font-17': $vuetify.breakpoint.mdAndUp, 'font-15': $vuetify.breakpoint.smAndDown}"
                              class="graphik-medium mb-2 dark-grey--text"
                            >{{ $ml.get('fields_payment_method') }}</p>

                            <v-autocomplete
                              v-model="issuance.payment_type"
                              :items="options.payment_types"
                              solo
                              clearable
                              :disabled="g_isLoading || disabled"
                              required
                              :rules="g_required_rules"
                              :no-data-text="$ml.get('general_no_results')"
                              class="hidden-shadow small-input"
                            ></v-autocomplete>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>

                    <v-checkbox
                      :disabled="g_isLoading || disabled"
                      color="dark-grey"
                      class="pb-5 pt-0 mt-0"
                      v-model="driverIsSameAsContract"
                      :label="$ml.get('issuance_driver_is_same_as_contract')"
                    ></v-checkbox>
                  </v-flex>
                </v-layout>

                <v-layout row wrap v-if="!$route.params.policy">
                  <v-flex xs12 text-xs-right>
                    <v-btn 
                      color="primary" 
                      depressed flat
                      :loading="g_isLoading"
                      class="px-2 normalcase graphik-bold-italic my-0 mr-3 border-radius-5 white--text"
                      :disabled="g_isLoading"
                      @click="$router.push('/cotizar/' + $route.params.type + '/' + $route.params.quote)"
                    ><v-icon dark class="mr-2">arrow_back</v-icon>{{ $ml.get('general_back') }}</v-btn>
                    
                    <v-btn 
                      color="primary" 
                      depressed
                      :loading="g_isLoading"
                      class="pr-3 pl-4 normalcase graphik-bold-italic my-0 ml-3 border-radius-5 white--text"
                      :disabled="g_isLoading || !valid_contact"
                      @click="createIssuance"
                    >{{ $ml.get('general_continue') }}<v-icon dark class="ml-2">arrow_forward</v-icon></v-btn>
                  </v-flex>
                </v-layout>

                <template v-if="$route.params.policy">
                  <v-divider class="grey my-4"></v-divider>

                  <v-layout row wrap v-for="(section, index) in sections" :key="index">
                    <v-flex xs12 class="max-width-1400 full-width ma-auto">
                      <h3 
                        :class="{'font-20': $vuetify.breakpoint.mdAndUp, 'font-17': $vuetify.breakpoint.smAndDown}"
                        class="graphik-bold primary--text my-4"
                      > {{ section.title }}</h3>

                      <v-container fluid pa-0 ma-0 grid-list-xl>
                        <v-layout row wrap>
                          <v-flex xs12 sm6 md4 v-for="(field, index) in section.fields" :key="index">
                            <dynamic-field v-if="showChainable(field)" :options="field" :key="index" :value="issuance[field.back_end]" @update:value="setValue($event, field.back_end)" :editing="field.disabled" @save:value="saveAnswer($event, answer_uuids[field.back_end])" />
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-flex>
                  </v-layout>
                </template>

                <v-layout row wrap pt-3 v-if="$route.params.policy">
                  <v-flex xs12 text-xs-right>
                    <v-btn 
                      color="primary" 
                      depressed flat
                      :loading="g_isLoading"
                      class="px-2 normalcase graphik-bold-italic my-0 mr-3 border-radius-5 white--text"
                      :disabled="g_isLoading"
                      @click="$router.push('/cotizar/' + $route.params.type + '/' + $route.params.quote)"
                    ><v-icon dark class="mr-3">arrow_back</v-icon>{{ $ml.get('general_back') }}</v-btn>
                    
                    <v-btn 
                      color="primary" 
                      depressed
                      :loading="g_isLoading"
                      class="pr-3 pl-4 normalcase graphik-bold-italic my-0 ml-3 border-radius-5 white--text"
                      :disabled="g_isLoading"
                      @click="finishIssuance(10)"
                    >{{ $ml.get('general_issuance') }}<v-icon dark class="ml-2">arrow_forward</v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>
          </v-flex>

          <!-- Web & Tablet -->
          <v-flex xs12 sm5 md4 :class="{'pa-3': $vuetify.breakpoint.mdAndUp, 'pl-2 pr-3 py-3': $vuetify.breakpoint.smOnly}" v-if="product.product && $vuetify.breakpoint.smAndUp">
            <v-card color="light-background" flat tile>
              <v-card-text>
                <h3 class="text-xs-center graphik-bold font-20 primary--text mb-0">{{ $ml.get('general_order_summary') }}</h3>

                <v-img
                  v-if="company_logo"
                  max-width="250px"
                  contain
                  position="center center"
                  class="ma-auto"
                  :src="company_logo"
                  :lazy-src="company_logo"
                />

                <p class="graphik-bold font-17 dark-grey--text">{{ $ml.get('quoter_insurace') }} {{ $route.params.type }}</p>

                <p class="graphik-medium uppercase font-17 dark-grey--text ma-0">{{ $ml.get('fields_coverage') }} {{ product.product.public_name }}</p>

                <v-expansion-panel class="hidden-shadow" v-model="coverages_panel">
                  <v-expansion-panel-content class="light-background">
                    <template v-slot:header>
                      <p class="graphik-medium font-14 dark-grey--text ma-0">{{ $ml.get('general_view_details') }} <v-icon small>{{ coverages_panel === 0 ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</v-icon></p>
                    </template>

                    <v-card flat height="100%" class="light-background">
                      <v-card-text class="pa-0">
                        <p class="graphik-light font-14 dark-grey--text ma-0" v-for="(coverage, index) in product.insurance_information.coverage" :key="index">• {{ coverage.text }}</p>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-divider class="grey my-3"></v-divider>

                <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_validity') }}</p>
                <p class="graphik-light font-15 dark-grey--text">{{ product.quote.start_date | moment('LL') }} {{ $ml.get('general_to') }} {{ product.quote.end_date | moment('LL') }}</p>

                <template v-if="$route.params.type === 'auto'">
                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_model') }}</p>
                  <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_model }}</p>

                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_brand') }}</p>
                  <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_brand }}</p>

                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_car_uuid') }}</p>
                  <p class="graphik-light font-15 dark-grey--text">{{ product.quote.car_version }}</p>
                </template>

                <template v-if="$route.params.type === 'hogar'">
                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_address') }}</p>
                  <p class="graphik-light font-15 dark-grey--text">{{ product.quote.street }} {{ product.quote.street_number }}, {{ product.quote.neighborhood }} {{ product.quote.zip_code }}. {{ product.quote.city }}, {{ product.quote.state }}.</p>
                </template>

                <v-divider class="grey my-3"></v-divider>

                <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_coupon') }}</p>
                <p class="graphik-light font-15 dark-grey--text">{{ product.quote.coupon.coupon_code }}</p>

                <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_payment_method') }}</p>
                <p class="graphik-light font-15 dark-grey--text">{{ product.quote.frequency_description }}</p>

                <v-divider class="grey my-3"></v-divider>

                <v-flex d-flex>
                  <p class="graphik-medium font-20 dark-grey--text ma-0 uppercase">{{ $ml.get('fields_total') }}</p>
                  <v-spacer></v-spacer>
                  <p class="graphik-light font-20 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.total | price }}</p>
                </v-flex>

                <v-divider class="grey my-3"></v-divider>

                <v-flex d-flex>
                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ $ml.get('fields_first_payment') }}</p>
                  <v-spacer></v-spacer>
                  <p class="graphik-light font-15 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.first_payment | price }}</p>
                </v-flex>

                <v-flex d-flex v-if="product.insurance_information.next_payment">
                  <p class="graphik-medium font-15 dark-grey--text ma-0">{{ product.insurance_information.frequency - 1 }} {{ $ml.get('fields_next_payment') }}</p>
                  <v-spacer></v-spacer>
                  <p class="graphik-light font-15 dark-grey--text ma-0 text-xs-right">{{ product.insurance_information.next_payment | price }}</p>
                </v-flex>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <message-dialog :dialog="g_dialog"></message-dialog>
    <loading-dialog :isLoading="g_isLoading"></loading-dialog>
    <validate-car-dialog
      v-if="$route.params.type === 'auto'"
      :show="validate_car_dialog"
      :quote="$route.params.quote"
      :quote_price="$route.params.quote_price"
      :product="$route.params.product"
      @close="validate_car_dialog = false"
    />
  </v-container>
</template>

<script>
export default {
  name: 'Issuance',
  metaInfo: {
    title: process.env.VUE_APP_METATAGS_TITLE_ISSUANCE,
    meta: [
      {
        vmid: 'og:title',
        property: 'og:title',
        content: process.env.VUE_APP_METATAGS_TITLE_ISSUANCE
      },
      {
        vmid: 'twitter:title',
        property: 'twitter:title',
        content: process.env.VUE_APP_METATAGS_TITLE_ISSUANCE
      }
    ]
  },
  data () {
    return {
      panel: null,
      birthdate_menu: false,
      driverIsSameAsContract: true,
      valid_contact: false,
      coverages_panel: null,
      valid_issuance: false,
      sections: [],
      issuance: {
        birthday: null
      },
      answer_uuids: {},
      product: {},
      saving_answer: {},
      validate_car_dialog: !this.$route.params.policy,
      options: {
        payment_types: [
          {
            text: this.$ml.get('general_buy_card'),
            value: 0
          },
          {
            text: this.$ml.get('general_buy_cash'),
            value: 1
          }
        ],
        genders: [
          {
            text: this.$ml.get('fields_options_gender_male'),
            value: 'H',
            icon: 'fas fa-male'
          },
          {
            text: this.$ml.get('fields_options_gender_female'),
            value: 'M',
            icon: 'fas fa-female'
          }
        ]
      }
    }
  },
  components: {
    'validate-car-dialog': () => import(/* webpackPrefetch: true */ '../components/ValidateCarDialog')
  },
  watch: {
    birthdate_menu (value) {
      value && this.$nextTick(() => (this.$refs.birthdate_picker.activePicker = 'YEAR'))
    },
  },
  computed: {
    disabled () {
      return !!this.$route.params.policy
    },
    birthdate_formatted: {
      get () {
        if (!this.issuance.birthday) return null

        const [year, month, day] = this.issuance.birthday.split('-')
        return `${day}/${month}/${year}`
      },
      set () {
        this.issuance.birthday = this.issuance.birthday
      }
    },
    adult_only () {
      return this.$moment().subtract(18, 'years').format('YYYY-MM-DD')
    },
    company_logo () {
      if (!this.product.product) {
        return null
      }

      if (this.$route.params.type === 'auto') {
        return require('@/assets/img/companies/' + this.product.product.company_internal_name + '-full.png')
      }

      if (this.$route.params.type === 'hogar') {
        return require('@/assets/img/companies/' + this.product.product.company_internal_name + 'hogar-' + this.product.product.product_package_index + '-full.png')
      }

      return null
    }
  },
  methods: {
    // CRUD
    getProduct () {
      this.g_isLoading = true
      this.$api.getIssuanceProduct(
        this.$route.params.product,
        this.$route.params.quote,
        this.$route.params.frequency,
        response => {
          this.product = response.body.data
          if (this.$route.params.policy) {
            this.issuance.payment_type = response.body.data.payment_type
            this.issuance.birthday = response.body.data.quote.birthday
            this.getFields()
          } else {
            this.issuance.email = response.body.data.quote.email
            this.issuance.cellphone = response.body.data.quote.cellphone
            this.issuance.first_name = response.body.data.quote.name
            this.issuance.last_name = response.body.data.quote.last_name
            this.issuance.second_last_name = response.body.data.quote.second_last_name
            this.issuance.gender = response.body.data.quote.gender
            this.issuance.payment_type = this.$route.query.cash ? 1 : 0
            this.g_isLoading = false
          }
        },
        response => this.$errorHandling(response).then(() => {
          this.getProduct()
        }, () => {})
      )
    },
    getFields () {
      this.g_isLoading = true
      this.$api.getIssuanceFields(
        this.$route.params.policy,
        this.driverIsSameAsContract ? '0' : '1',
        response => {
          this.sections = response.body.data.step
          this.$nextTick(() => {
            setTimeout(() => {
              response.body.data.answer.map(answer => {
                this.answer_uuids[answer.back_end] = answer.uuid
                this.saving_answer[answer.uuid] = false
                if (answer.answer) {
                  this.issuance[answer.back_end] = answer.answer
                }
              })

              this.g_isLoading = false
            }, 500)
          })
          
        },
        response => this.$errorHandling(response).then(() => {
          this.getFields()
        }, () => {})
      )
    },
    createIssuance () {
      this.g_isLoading = true
      let payload = {
        product_uuid: this.$route.params.product,
        quote_uuid: this.$route.params.quote,
        quote_price_uuid: this.$route.params.quote_price,
        frequency: this.$route.params.frequency,
        first_name: this.issuance.first_name,
        last_name: this.issuance.last_name,
        second_last_name: this.issuance.second_last_name,
        email: this.issuance.email,
        birthday: this.issuance.birthday,
        cellphone: this.issuance.cellphone,
        payment_type: this.issuance.payment_type,
        contract: this.driverIsSameAsContract ? '0' : '1'
      }

      this.$api.createIssuance(
        payload,
        response => {
          this.$goTo(this.$route.fullPath.replace('?cash=true', '') + '/' + response.body.data.uuid, 'issuance_create_issuance')
          this.g_isLoading = false
        },
        response => this.$errorHandling(response).then(() => {
          this.createIssuance()
        }, () => {})
      )
    },
    saveAnswer (answer, uuid) {
      this.saving_answer[uuid] = true
      this.$api.saveIssuance(
        uuid,
        { answer: answer || '' },
        () => {
          this.saving_answer[uuid] = false
        },
        response => this.$errorHandling(response).then(() => {
          this.saveAnswer(answer, uuid)
        }, () => {})
      )
    },
    finishIssuance (retry) {
      this.g_isLoading = true
      let still_saving = false
      Object.keys(this.saving_answer).map(key => {
        if (this.saving_answer[key]) still_saving = true
      })

      if (still_saving) {
        setTimeout(() => {
          this.finishIssuance(retry - 1)
        },500)
        return
      }

      this.$api.finishIssuance(
        this.$route.params.policy,
        response => {
          this.g_isLoading = false
          this.$analytics('issuance_finish_issuance')
          window.open(response.body.data.open_payment_url, '_self')
        },
        response => this.$errorHandling(response).then(() => {
          this.finishIssuance(10)
        }, () => {})
      )
    },
    // Helping methods
    showChainable (field) {
      if (!field.chainable) {
        return true
      }
      let result = false
      field.chainable_conditions.map(condition => {
        if (condition.value === this.issuance[condition.field]) {
          result = true
        }
      })
      return result
    },
    setValue (value, key) {
      this.issuance[key] = value
      this.$forceUpdate()
    }
  },
  mounted () {
    this.getProduct()
  }
}
</script>

<style scoped>
</style>
